import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

export function ServiceForm({
  alert,
  categories,
  loading,
  resetWhenSuccess,
  skills,
  types,
  values = {},
  onSubmit,
}) {
  const {
    control,
    formState: { isSubmitSuccessful },
    handleSubmit,
    register,
    reset,
  } = useForm();

  React.useEffect(() => {
    if (resetWhenSuccess && isSubmitSuccessful) {
      reset({ ...values });
    }
  }, [isSubmitSuccessful, reset, resetWhenSuccess, values]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="serviceFormName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={values.name}
          name="name"
          placeholder="Name"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Controller
          control={control}
          name="status"
          defaultValue={values.status}
          render={({ value, onChange }) => (
            <>
              <Form.Check
                inline
                checked={!!value}
                label="On"
                type="radio"
                id="svconfFormStatusOn"
                onChange={() => onChange(1)}
              />
              <Form.Check
                inline
                checked={!value}
                label="Off"
                type="radio"
                id="svconfFormStatusOff"
                onChange={() => onChange(0)}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group controlId="serviceFormCode">
        <Form.Label>Code</Form.Label>
        <Form.Control
          defaultValue={values.code}
          name="code"
          placeholder="Code"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group controlId="serviceFormBaseCost">
        <Form.Label>Base Cost</Form.Label>
        <Form.Control
          defaultValue={values.baseCost}
          name="baseCost"
          placeholder="Base Cost"
          ref={register}
          type="number"
        />
      </Form.Group>
      <Form.Group controlId="serviceFormType">
        <Form.Label>Type</Form.Label>
        <Controller
          control={control}
          name="type"
          defaultValue={values.type}
          render={({ onChange }) => (
            <Select
              options={types}
              onChange={(selected) => onChange(selected.value)}
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="serviceFormSkillId">
        <Form.Label>Skill</Form.Label>
        <Controller
          control={control}
          name="skillId"
          defaultValue={values.skillId}
          render={({ onChange }) => (
            <Select
              options={skills}
              onChange={(selected) => onChange(selected.value)}
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="serviceFormCategoryId">
        <Form.Label>Category</Form.Label>
        <Controller
          control={control}
          name="categoryId"
          defaultValue={values.categoryId}
          render={({ onChange }) => (
            <Select
              options={categories}
              onChange={(selected) => onChange(selected.value)}
            />
          )}
        />
      </Form.Group>
      <Button type="submit" variant="primary" disabled={loading}>
        {loading ? <Spinner animation="border" /> : 'Gửi'}
      </Button>
    </Form>
  );
}
