exports.CONTRIBUTE_PRODUCT_STATUS = {
  NEW: 1,
  APPROVED: 2,
  REJECTED: 3,
};

exports.JOB_TYPES = {
  INSTALL: 1,
  REPAIR: 2,
};

exports.JOB_PAYMENT_TYPES = {
  CASH: 1,
};

exports.JOB_STATUS = {
  NEW: 1,
  ASSIGNED: 2,
  CONFIRMED: 3,
  ARRIVED: 4,
  WORKING: 5,
  DONE: 6,
  CANCEL_BY_WORKER: 7,
  CANCEL_BY_ADMIN: 8,
  CANCEL_BY_CUSTOMER: 9,
};

exports.SKILL_TYPES = {
  INSTALL: 1,
  REPAIR: 2,
  OTHER: 3,
};

exports.SKILL_STATUS = {
  CREATED: 1,
  ARCHIVED: 2,
};

exports.USER_ROLES = {
  WORKER: 1,
  COMPANY: 2,
  CONSUMER: 3,
  SUPER_ADMIN: 4,
  ADMIN: 5,
};

exports.USER_STATUS = {
  CREATED: 1,
  ACTIVE: 2,
  INACTIVE: 3,
  DISABLED: 4,
};

exports.WARRANTY_STATUS = {
  REQUESTED: 1,
  APPROVED: 2,
  CANCELED: 3,
  REJECTED: 4,
};

exports.JOB_COD_STATUS = {
  NO_COD: 1,
  PAY_FOR_WORKER: 2,
  PAY_FOR_COMPANY: 3,
};

exports.GENDER = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};
