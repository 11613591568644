import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { ProductForm } from './forms/ProductForm';
import { selectProductEntity, updateProduct } from './productSlice';

export function ProductEdit() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [alert, setAlert] = useState(null);
  const product = useSelector(selectProductEntity);
  const categoryMapping = useSelector((state) => state.category.entities);

  if (!state) {
    return <Redirect to="/product" />;
  }

  return (
    <Row className="justify-content-center">
      <Col md={4}>
        <h4>
          <Link to="/product">Trở về</Link>
        </h4>
        <hr />
        <ProductForm
          alert={
            alert && <Alert variant={alert.variant}>{alert.content}</Alert>
          }
          categories={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          loading={product.loading === 'pending'}
          values={{
            name: state.name,
            sku: state.sku,
            categoryId: state.categoryId,
            description: state.description,
            variations: state.variations.note,
            attachments: state.attachments.map((v) => v.uri).join('\n'),
          }}
          onSubmit={async (data) => {
            setAlert(null);

            try {
              const result = await dispatch(
                updateProduct({
                  ...state,
                  name: data.name,
                  sku: data.sku,
                  description: data.description,
                  variations: { note: data.note },
                  attachments: data.attachments
                    .split('\n')
                    .map((v) => ({ type: 'image', uri: v })),
                  categoryId: data.categoryId,
                })
              );

              unwrapResult(result);
              setAlert({ variant: 'success', content: 'Thành công!' });
            } catch (e) {
              setAlert({ variant: 'danger', content: e.message });
            }
          }}
        />
      </Col>
    </Row>
  );
}
