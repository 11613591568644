import React from 'react';
import BSPagination from 'react-bootstrap/Pagination';

export function Pagination({
  totalItem,
  totalPage,
  itemPerPage = 20,
  currentPage,
  onPageChange,
}) {
  if (totalItem <= itemPerPage) {
    return null;
  }

  const maxPaginationItemInMiddle = 5;
  let paginationItemsInMiddle = null;

  if (totalPage - 2 !== 0) {
    const renderPaginationItem = (value, offset) => (
      <BSPagination.Item
        key={value + offset}
        active={currentPage === value + offset}
        onClick={onPageChange.bind(null, value + offset)}
      >
        {value + offset}
      </BSPagination.Item>
    );

    if (totalPage < maxPaginationItemInMiddle + 4) {
      paginationItemsInMiddle = [...Array(totalPage - 2).keys()].map((value) =>
        renderPaginationItem(value, 2)
      );
    } else if (currentPage < maxPaginationItemInMiddle) {
      const offset = 2;

      paginationItemsInMiddle = [
        ...Array(maxPaginationItemInMiddle).keys(),
      ].map((value) => renderPaginationItem(value, offset));

      paginationItemsInMiddle.push(
        <BSPagination.Ellipsis disabled key="ellipsis-after" />
      );
    } else if (currentPage > totalPage - maxPaginationItemInMiddle) {
      const offset = totalPage - maxPaginationItemInMiddle;

      paginationItemsInMiddle = [
        ...Array(maxPaginationItemInMiddle).keys(),
      ].map((value) => renderPaginationItem(value, offset));

      paginationItemsInMiddle.unshift(
        <BSPagination.Ellipsis disabled key="ellipsis-before" />
      );
    } else {
      const offset = currentPage - maxPaginationItemInMiddle + 3;

      paginationItemsInMiddle = [
        ...Array(maxPaginationItemInMiddle).keys(),
      ].map((value) => renderPaginationItem(value, offset));

      paginationItemsInMiddle.push(
        <BSPagination.Ellipsis disabled key="ellipsis-after" />
      );
      paginationItemsInMiddle.unshift(
        <BSPagination.Ellipsis disabled key="ellipsis-before" />
      );
    }
  }

  return (
    <BSPagination>
      <BSPagination.First
        disabled={currentPage === 1}
        onClick={onPageChange.bind(null, 1)}
      />
      <BSPagination.Prev
        disabled={currentPage === 1}
        onClick={onPageChange.bind(null, currentPage - 1)}
      />
      <BSPagination.Item
        active={currentPage === 1}
        onClick={onPageChange.bind(null, 1)}
      >
        1
      </BSPagination.Item>
      {paginationItemsInMiddle}
      <BSPagination.Item
        active={currentPage === totalPage}
        onClick={onPageChange.bind(null, totalPage)}
      >
        {totalPage}
      </BSPagination.Item>
      <BSPagination.Next
        disabled={currentPage === totalPage}
        onClick={onPageChange.bind(null, currentPage + 1)}
      />
      <BSPagination.Last
        disabled={currentPage === totalPage}
        onClick={onPageChange.bind(null, totalPage)}
      />
    </BSPagination>
  );
}
