import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

export function ProductForm({
  alert,
  loading,
  categories,
  resetWhenSuccess,
  values = {},
  onSubmit,
}) {
  const {
    control,
    formState: { isSubmitSuccessful },
    handleSubmit,
    register,
    reset,
  } = useForm();

  React.useEffect(() => {
    if (resetWhenSuccess && isSubmitSuccessful) {
      reset({ ...values });
    }
  }, [isSubmitSuccessful, reset, resetWhenSuccess, values]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="productFormName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={values.name}
          name="name"
          placeholder="Name"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group controlId="productFormSKU">
        <Form.Label>SKU</Form.Label>
        <Form.Control
          defaultValue={values.sku}
          name="sku"
          placeholder="SKU"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group controlId="productFormCategoryID">
        <Form.Label>Category</Form.Label>
        <Controller
          control={control}
          name="categoryId"
          defaultValue={values.categoryId}
          render={({ onChange }) => (
            <Select
              options={categories}
              onChange={(selected) => onChange(selected.value)}
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="productFormDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          defaultValue={values.description}
          name="description"
          placeholder="Description"
          ref={register}
          rows="3"
        />
      </Form.Group>
      <Form.Group controlId="productFormNote">
        <Form.Label>Note</Form.Label>
        <Form.Control
          as="textarea"
          defaultValue={values.note}
          name="note"
          placeholder="Note"
          ref={register}
          rows="3"
        />
      </Form.Group>
      <Form.Group controlId="productFormAttachments">
        <Form.Label>Attachments</Form.Label>
        <Form.Control
          as="textarea"
          defaultValue={values.attachments}
          name="attachments"
          placeholder="Mỗi hình 1 dòng"
          ref={register}
          rows="3"
        />
      </Form.Group>
      <Button type="submit" variant="primary" disabled={loading}>
        {loading ? <Spinner animation="border" /> : 'Gửi'}
      </Button>
    </Form>
  );
}
