import * as constants from '../utils/constants';

export const formatCODStatus = (status) => {
  switch (status) {
    case constants.JOB_COD_STATUS.NO_COD:
      return 'Không thu tiền mặt';
    case constants.JOB_COD_STATUS.PAY_FOR_WORKER:
      return 'Thợ đã thu tiền của khách';
    case constants.JOB_COD_STATUS.PAY_FOR_COMPANY:
      return 'Thợ đã đưa tiền COD cho công ty';
    default:
      return status;
  }
};

export const formatJobStatus = (status) => {
  switch (status) {
    case constants.JOB_STATUS.NEW:
      return 'Công việc mới';
    case constants.JOB_STATUS.ASSIGNED:
      return 'Đã giao cho thợ';
    case constants.JOB_STATUS.ARRIVED:
      return 'Thợ đã đến địa điểm ';
    case constants.JOB_STATUS.WORKING:
      return 'Thợ đang làm';
    case constants.JOB_STATUS.DONE:
      return 'Thợ đã làm xong';
    case constants.JOB_STATUS.CANCEL_BY_ADMIN:
      return 'Đã huỷ bởi admin';
    case constants.JOB_STATUS.CANCEL_BY_CUSTOMER:
      return 'Đã huỷ bởi customer';
    case constants.JOB_STATUS.CANCEL_BY_WORKER:
      return 'Đã huỷ bởi worker';
    default:
      return 'Không rõ trạng thái';
  }
};

export const formatJobType = (type) => {
  switch (type) {
    case constants.JOB_TYPES.INSTALL:
      return 'Lắp đặt';
    case constants.JOB_TYPES.REPAIR:
      return 'Sửa chữa';

    default:
      return 'Không rõ';
  }
};

export const formatSkillType = (type) => {
  switch (type) {
    case constants.SKILL_TYPES.INSTALL:
      return 'Lắp đặt';
    case constants.SKILL_TYPES.REPAIR:
      return 'Sửa chữa';
    case constants.SKILL_TYPES.OTHER:
      return 'Khác';

    default:
      return 'Không rõ';
  }
};
