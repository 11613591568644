import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const fetchListServiceJobType = createAsyncThunk(
  'service/fetchListServiceJobType',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListServiceJobType(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchListService = createAsyncThunk(
  'service/fetchListService',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListService(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const createService = createAsyncThunk(
  'service/createService',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.createService(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

export const updateService = createAsyncThunk(
  'service/updateService',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.updateService(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

const serviceAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const serviceSlice = createSlice({
  initialState: serviceAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
    jobTypes: [],
  }),
  name: 'service',
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [fetchListService.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListService.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        serviceAdapter.setAll(state, payload.rows);
        state.totalPage = payload.totalPage;
        state.count = payload.count;
      }
    },
    [fetchListService.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [fetchListServiceJobType.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListServiceJobType.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        state.jobTypes = payload;
      }
    },
    [fetchListServiceJobType.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [createService.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [createService.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        serviceAdapter.addOne(state, payload);
      }
    },
    [createService.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [updateService.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [updateService.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        serviceAdapter.updateOne(state, payload);
      }
    },
    [updateService.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectServiceEntity = (state) => state.service;

export const { setError } = serviceSlice.actions;

export default serviceSlice.reducer;
