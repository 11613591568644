import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';

export function SkillForm({
  loading,
  skillTypes,
  alert,
  formType,
  onSubmit,
  values = {},
}) {
  const { register, handleSubmit } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="skillFormName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={values.name}
          name="name"
          placeholder="Name"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group controlId="skillFormCode">
        <Form.Label>Code</Form.Label>
        <Form.Control
          defaultValue={values.code}
          name="code"
          placeholder="VD: MANUAL_LOCKER, REPAIR_FIX_SAFETY_BOX"
          ref={register}
          type="text"
        />
      </Form.Group>
      {formType !== 'edit' && (
        <Form.Group controlId="skillFormType">
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            custom
            ref={register}
            defaultValue={values.type}
            name="type"
            placeholder="Type"
          >
            {skillTypes.map((skillType) => (
              <option key={skillType.id} value={skillType.id}>
                {skillType.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      <Form.Group controlId="skillFormDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          defaultValue={values.description}
          name="description"
          placeholder="Description"
          ref={register}
          rows="3"
        />
      </Form.Group>
      <Button type="submit" variant="primary">
        {loading ? <Spinner animation="border" /> : 'Gửi'}
      </Button>
    </Form>
  );
}
