import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';

export function ChangeCostForm({ id, alert, disabled, onSubmit, values = {} }) {
  const { register, handleSubmit } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId={`ChangeCostFormCostForWorker-${id}`}>
        <Form.Label>Cost for worker</Form.Label>
        <Form.Control
          defaultValue={values.costForWorker}
          name="costForWorker"
          placeholder="Input cost for worker"
          ref={register}
          type="number"
          required
        />
      </Form.Group>
      <Form.Group controlId={`ChangeCostFormCodAmount${id}`}>
        <Form.Label>Cod Amount</Form.Label>
        <Form.Control
          defaultValue={values.codAmount}
          name="codAmount"
          placeholder="Input cod amount"
          ref={register}
          type="number"
          required
        />
      </Form.Group>
      <Button disabled={disabled} type="submit" variant="primary">
        {disabled ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          'Xác nhận'
        )}
      </Button>
    </Form>
  );
}
