import { configureStore } from '@reduxjs/toolkit';

import category from '../features/admin/categorySlice';
import job from '../features/admin/jobSlice';
import product from '../features/admin/productSlice';
import service from '../features/admin/serviceSlice';
import skill from '../features/admin/skillSlice';
import skillType from '../features/admin/skillTypeSlice';
import svconf from '../features/admin/svconfSlice';
import user from '../features/admin/userSlice';
import worker from '../features/admin/workerSlice';

const store = configureStore({
  reducer: {
    category,
    job,
    product,
    service,
    skill,
    skillType,
    svconf,
    user,
    worker,
  },
});

export default store;
