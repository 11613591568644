import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const fetchListWorker = createAsyncThunk(
  'worker/fetchListWorker',
  async (params) => {
    try {
      const p = { ...params };
      let data = await api.fetchListWorker(p);

      for (let i = 2; i <= data.totalPage; i++) {
        const tempData = await api.fetchListWorker({ page: i });
        data.page = tempData.page;
        data.count = tempData.count;
        data.totalPage = tempData.totalPage;
        data.rows.push(...tempData.rows);
      }

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

const workerAdapter = createEntityAdapter({ selectId: (entity) => entity.id });

const workerSlice = createSlice({
  initialState: workerAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  }),
  name: 'worker',
  reducers: {},
  extraReducers: {
    [fetchListWorker.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListWorker.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.totalPage = payload.totalPage;
        state.count = payload.count;

        workerAdapter.setAll(state, payload.rows);
      }
    },
    [fetchListWorker.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectWorker = (id) => (state) => state.worker.entities[id];

export const selectWorkerEntity = (state) => ({
  ids: state.worker.ids,
  entities: state.worker.entities,
  totalPage: state.worker.totalPage,
  count: state.worker.count,
});

export default workerSlice.reducer;
