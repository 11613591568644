import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const fetchListSvconf = createAsyncThunk(
  'svconf/fetchListSvconf',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListSvconf(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const createSvconf = createAsyncThunk(
  'svconf/createSvconf',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.createSvconf(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

export const updateSvconf = createAsyncThunk(
  'svconf/updateSvconf',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.updateSvconf(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

const svconfAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const svconfSlice = createSlice({
  initialState: svconfAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  }),
  name: 'svconf',
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [fetchListSvconf.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListSvconf.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        svconfAdapter.setAll(state, payload.services);
      }
    },
    [fetchListSvconf.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [createSvconf.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [createSvconf.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        svconfAdapter.addOne(state, payload);
      }
    },
    [createSvconf.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [updateSvconf.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [updateSvconf.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        svconfAdapter.updateOne(state, payload);
      }
    },
    [updateSvconf.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectSvconfEntity = (state) => state.svconf;

export const { setError } = svconfSlice.actions;

export default svconfSlice.reducer;
