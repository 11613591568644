import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import * as constants from '../../utils/constants';
import { SkillForm } from './forms/SkillForm';
import {
  createSkill,
  deleteSkill,
  fetchListSkill,
  selectSkillEntity,
} from './skillSlice';
import { fetchListSkillType, selectSkillTypeEntity } from './skillTypeSlice';
import { selectUser } from './userSlice';

export function Skill() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const skill = useSelector(selectSkillEntity);
  const skillType = useSelector(selectSkillTypeEntity);
  const mapSkillType = skillType.mapSkillType;
  const skillTypes = skillType.ids.map((id) => skillType.entities[id]);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchListSkill());
      dispatch(fetchListSkillType());
    }
  }, [dispatch, user.isLoggedIn]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const handleCreateSkill = (data) => {
    dispatch(createSkill(data));
  };

  const handleDeleteSkill = (data) => {
    dispatch(deleteSkill(data));
  };

  return (
    <Row>
      <Col>
        <h4>Skill</h4>
        <hr />
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Code</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {skill.ids.map((id) => {
              if (skill.entities[id].status !== constants.SKILL_STATUS.ARCHIVED)
                return (
                  <tr key={id}>
                    <td>{skill.entities[id].name}</td>
                    <td>{mapSkillType[skill.entities[id].type]}</td>
                    <td>{skill.entities[id].code}</td>
                    <td>{skill.entities[id].description}</td>
                    <td>
                      <Link
                        to={{
                          pathname: '/skill/edit',
                          state: skill.entities[id],
                        }}
                      >
                        Sửa
                      </Link>
                      <Button
                        size="sm"
                        type="button"
                        variant="danger"
                        className="ml-1"
                        onClick={() => {
                          handleDeleteSkill({
                            skillId: skill.entities[id].id,
                          });
                        }}
                      >
                        {skill.loading ? <Spinner animation="border" /> : 'Xóa'}
                      </Button>
                    </td>
                  </tr>
                );

              return null;
            })}
          </tbody>
        </Table>
      </Col>
      <Col md={3}>
        <h4>Skill Form</h4>
        <SkillForm
          onSubmit={(data) => {
            handleCreateSkill(data);
          }}
          alert={
            skill.error && (
              <Alert key="error" variant="danger">
                {skill.error.message}
              </Alert>
            )
          }
          skillTypes={skillTypes}
          loading={skill.loading}
          values={{
            name: '',
            code: '',
            type: 'install',
            description: '',
          }}
        />
      </Col>
    </Row>
  );
}
