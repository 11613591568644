import { createSlice } from '@reduxjs/toolkit';

import * as api from '../../app/api';

const userSlice = createSlice({
  initialState: { errorMessage: '', isLoggedIn: false },
  name: 'user',
  reducers: {
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
  },
});

export const { setErrorMessage, setIsLoggedIn } = userSlice.actions;

export const checkLoggedIn = () => async (dispatch) => {
  const token = window.localStorage.getItem('token');

  if (!!token) {
    api.attachToken(token);
    dispatch(setIsLoggedIn(true));
  } else {
    dispatch(setIsLoggedIn(false));
  }
};

export const logIn = (body) => async (dispatch) => {
  dispatch(setErrorMessage(''));

  try {
    const data = await api.logIn(body);

    window.localStorage.setItem('token', data.accessToken);
    api.attachToken(data.accessToken);
    dispatch(setIsLoggedIn(true));
  } catch (e) {
    dispatch(setErrorMessage(e.response?.data?.reason || 'MSG_ERR'));
  }
};

export const selectUser = (state) => state.user;

export default userSlice.reducer;
