import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Pagination } from '../../components/Pagination';
import { fetchListCategory } from './categorySlice';
import { ProductForm } from './forms/ProductForm';
import {
  createProduct,
  fetchListProduct,
  selectProductEntity,
} from './productSlice';
import { selectUser } from './userSlice';

export function Product() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const product = useSelector(selectProductEntity);
  const categoryMapping = useSelector((state) => state.category.entities);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchListCategory());
      dispatch(fetchListProduct({ page }));
    }
  }, [dispatch, user.isLoggedIn, page]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Row>
      <Col>
        <h4>Product</h4>
        <hr />
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Name</th>
              <th>Category</th>
              <th>Category Code</th>
              <th>Description</th>
              <th>Note</th>
              <th>Attachments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {product.ids.map((id) => (
              <tr key={id}>
                <td>{product.entities[id].sku}</td>
                <td>{product.entities[id].name}</td>
                <td>
                  {categoryMapping[product.entities[id].categoryId]?.name}
                </td>
                <td>{product.entities[id].categoryCode}</td>
                <td>{product.entities[id].description}</td>
                <td>{product.entities[id].variations.note}</td>
                <td>
                  {product.entities[id].attachments.map((a, i) => (
                    <React.Fragment key={i}>
                      <a href={a.uri}>{`${a.type}${i}`}</a>{' '}
                    </React.Fragment>
                  ))}
                </td>
                <td>
                  <Link
                    to={{
                      pathname: '/product/edit',
                      state: product.entities[id],
                    }}
                  >
                    Sửa
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={page}
          totalPage={product.totalPage}
          totalItem={product.count}
          onPageChange={(page) => setPage(page)}
        />
      </Col>
      <Col md={3}>
        <h4>Product Form</h4>
        <ProductForm
          alert={
            product.error && (
              <Alert key="error" variant="danger">
                {product.error.message}
              </Alert>
            )
          }
          loading={product.loading === 'pending'}
          categories={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          resetWhenSuccess
          values={{
            name: '',
            sku: '',
            categoryId: null,
            description: '',
            variations: '',
            attachments: '',
          }}
          onSubmit={async (data) => {
            const body = {
              name: data.name,
              sku: data.sku,
              description: data.description,
              variations: { note: data.note },
              attachments: data.attachments
                .split('\n')
                .map((v) => ({ type: 'image', uri: v })),
              categoryId: data.categoryId,
            };

            await dispatch(createProduct(body));
          }}
        />
      </Col>
    </Row>
  );
}
