import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const createJob = createAsyncThunk('job/createJob', async (body) => {
  try {
    const data = await api.createJob(body);

    return data;
  } catch (e) {
    throw new Error(e.response?.data?.reason);
  }
});

export const fetchCustomerInfo = createAsyncThunk(
  'job/fetchCustomerInfo',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchCustomerInfo(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchSearchAddress = createAsyncThunk(
  'job/fetchSearchAddress',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchSearchAddress(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchAddressSuggestion = createAsyncThunk(
  'job/fetchAddressSuggestion',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchAddressSuggestion(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchListJobType = createAsyncThunk(
  'job/fetchListJobType',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListJobType(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchListJob = createAsyncThunk(
  'job/fetchListJob',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListJob(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const assignJob = createAsyncThunk(
  'job/assignJob',
  async (body, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().job;

    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    try {
      const data = await api.assignJob(body);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const cancelJob = createAsyncThunk(
  'job/cancelJob',
  async (body, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().job;

    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    try {
      const data = await api.cancelJob(body);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const changeJobCost = createAsyncThunk(
  'job/changeJobCost',
  async (body, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().job;

    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    try {
      const data = await api.changeJobCost(body);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchJobDetail = createAsyncThunk(
  'job/fetchJobDetail',
  async (body, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().job;

    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    try {
      const data = await api.fetchJobDetail(body);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

const jobAdapter = createEntityAdapter({ selectId: (entity) => entity.id });

const jobSlice = createSlice({
  initialState: jobAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    jobDetail: null,
    addressDetail: null,
    suggestionAddresses: [],
    currentRequestId: undefined,
    error: null,
  }),
  name: 'job',
  reducers: {},
  extraReducers: {
    [fetchSearchAddress.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchSearchAddress.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        state.addressDetail = payload;
      }
    },
    [fetchSearchAddress.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [fetchAddressSuggestion.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchAddressSuggestion.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        state.suggestionAddresses = payload;
      }
    },
    [fetchAddressSuggestion.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [fetchListJob.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListJob.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.totalPage = payload.totalPage;
        state.count = payload.count;

        jobAdapter.setAll(state, payload.rows);
      }
    },
    [fetchListJob.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [assignJob.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [assignJob.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        jobAdapter.updateOne(state, {
          id: payload.jobId,
          changes: { status: payload.status },
        });
      }
    },
    [assignJob.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [cancelJob.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [cancelJob.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        jobAdapter.upsertOne(state, payload);
      }
    },
    [cancelJob.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [changeJobCost.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [changeJobCost.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        jobAdapter.upsertOne(state, payload);
      }
    },
    [changeJobCost.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [fetchJobDetail.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [fetchJobDetail.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        state.jobDetail = payload;
      }
    },
    [fetchJobDetail.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectJob = (id) => (state) => state.job.entities[id];

export const selectJobEntity = (state) => ({
  ids: state.job.ids,
  entities: state.job.entities,
  totalPage: state.job.totalPage,
  jobDetail: state.job.jobDetail,
  count: state.job.count,
  loading: state.job.loading,
});

export default jobSlice.reducer;
