import axios from 'axios';
import humps from 'humps';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-Api-Key': process.env.REACT_APP_API_KEY,
  },
  transformRequest: (data) => JSON.stringify(humps.camelizeKeys(data)),
  transformResponse: (data) => {
    try {
      const parsedData = JSON.parse(data);

      return humps.camelizeKeys(parsedData);
    } catch {
      return data;
    }
  },
});

export function attachToken(token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export async function fetchListJob(params) {
  try {
    const { data } = await instance.get('/api/admin/jobs', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function cancelJob(jobId) {
  try {
    const { data } = await instance.put(`/api/admin/jobs/${jobId}/cancel`);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function assignJob(body) {
  try {
    const { data } = await instance.put(
      `/api/admin/jobs/${body.jobId}/assign`,
      body
    );

    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function logIn(body) {
  try {
    const { data } = await instance.post('/api/auth/login', body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListWorker(params) {
  try {
    const { data } = await instance.get('/api/admin/workers', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListSkill(params) {
  try {
    const { data } = await instance.get('/api/admin/skills', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createSkill(body) {
  try {
    const { data } = await instance.post('/api/admin/skills', body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function changeJobCost(body) {
  try {
    const { data } = await instance.put(
      `/api/admin/jobs/${body.jobId}/cost`,
      body
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateSkill(body) {
  try {
    const { data } = await instance.put(
      `/api/admin/skills/${body.skillId}`,
      body
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteSkill(body) {
  try {
    const { data } = await instance.put(
      `/api/admin/skills/${body.skillId}/archived`,
      body
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchJobDetail(body) {
  try {
    const { data } = await instance.get(`/api/admin/jobs/${body.jobId}`);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListSkillType(params) {
  try {
    const { data } = await instance.get('/api/services/types', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListCategory(params) {
  try {
    const { data } = await instance.get('/api/admin/categories', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createCategory(body) {
  try {
    const { data } = await instance.post('/api/admin/categories', body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateCategory(body) {
  try {
    const { data } = await instance.put(
      `/api/admin/categories/${body.id}`,
      body
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListService(params) {
  try {
    const { data } = await instance.get('/api/admin/services', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createService(body) {
  try {
    const { data } = await instance.post('/api/admin/services', body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateService(body) {
  try {
    const { data } = await instance.put(`/api/admin/services/${body.id}`, body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListSvconf(params) {
  try {
    const { data } = await instance.get(
      '/api/admin/configs/consumers/services',
      { params }
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createSvconf(body) {
  try {
    const { data } = await instance.post(
      '/api/admin/configs/consumers/services',
      body
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateSvconf(body) {
  try {
    const { data } = await instance.put(
      `/api/admin/configs/consumers/services/${body.id}`,
      body
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListJobType(params) {
  try {
    const { data } = await instance.get('/api/admin/services', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListHomeCategory(params) {
  try {
    const { data } = await instance.get(
      '/api/admin/configs/consumers/services',
      {
        params,
      }
    );

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchCustomerInfo(params) {
  try {
    const { data } = await instance.get('api/admin/consumers', {
      params,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListChildCategory(params) {
  try {
    const { data } = await instance.get('api/categories', {
      params,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListServiceJobType(params) {
  try {
    const { data } = await instance.get('api/services/types', {
      params,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchSearchAddress(params) {
  try {
    const { data } = await instance.get('api/places/search', {
      params,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchAddressSuggestion(params) {
  try {
    const { data } = await instance.get('api/places/autocomplete', {
      params,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createJob(body) {
  try {
    const { data } = await instance.post('/api/admin/jobs', body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchListProduct(params) {
  try {
    const { data } = await instance.get('/api/admin/products', { params });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createProduct(body) {
  try {
    const { data } = await instance.post('/api/admin/products', body);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateProduct(body) {
  try {
    const { data } = await instance.put(`/api/admin/products/${body.id}`, body);

    return data;
  } catch (e) {
    throw e;
  }
}
