import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Pagination } from '../../components/Pagination';
import { fetchListCategory } from './categorySlice';
import { ServiceForm } from './forms/ServiceForm';
import {
  createService,
  fetchListService,
  selectServiceEntity,
} from './serviceSlice';
import { fetchListSkill } from './skillSlice';
import { fetchListSkillType } from './skillTypeSlice';
import { selectUser } from './userSlice';

export function Service() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const service = useSelector(selectServiceEntity);
  const skillMapping = useSelector((state) => state.skill.entities);
  const categoryMapping = useSelector((state) => state.category.entities);
  const typeMapping = useSelector((state) => state.skillType.entities);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchListCategory());
      dispatch(fetchListSkill());
      dispatch(fetchListSkillType());
      dispatch(fetchListService({ page }));
    }
  }, [dispatch, user.isLoggedIn, page]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Row>
      <Col>
        <h4>Service</h4>
        <hr />
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Code</th>
              <th>Type</th>
              <th>Skill</th>
              <th>Category</th>
              <th>Base Cost</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {service.ids.map((id) => (
              <tr key={id}>
                <td>{service.entities[id].name}</td>
                <td>{service.entities[id].status ? 'On' : 'Off'}</td>
                <td>{service.entities[id].code}</td>
                <td>{typeMapping[service.entities[id].type]?.name}</td>
                <td>{skillMapping[service.entities[id].skillId]?.name}</td>
                <td>
                  {categoryMapping[service.entities[id].categoryId]?.name}
                </td>
                <td>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(service.entities[id].baseCost)}
                </td>
                <td>
                  <Link
                    to={{
                      pathname: '/service/edit',
                      state: service.entities[id],
                    }}
                  >
                    Sửa
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={page}
          totalPage={service.totalPage}
          totalItem={service.count}
          onPageChange={(page) => setPage(page)}
        />
      </Col>
      <Col md={3}>
        <h4>Service Form</h4>
        <ServiceForm
          alert={
            service.error && (
              <Alert key="error" variant="danger">
                {service.error.message}
              </Alert>
            )
          }
          categories={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          loading={service.loading === 'pending'}
          resetWhenSuccess
          skills={Object.values(skillMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          types={Object.values(typeMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          values={{
            name: '',
            status: 1,
            code: '',
            baseCost: '',
            type: null,
            skillId: null,
            categoryId: null,
          }}
          onSubmit={async (data) => {
            await dispatch(createService(data));
          }}
        />
      </Col>
    </Row>
  );
}
