import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

export function CategoryForm({
  alert,
  loading,
  parent,
  resetWhenSuccess,
  values = {},
  onSubmit,
}) {
  const {
    control,
    formState: { isSubmitSuccessful },
    handleSubmit,
    register,
    reset,
  } = useForm();

  React.useEffect(() => {
    if (resetWhenSuccess && isSubmitSuccessful) {
      reset({ ...values });
    }
  }, [isSubmitSuccessful, reset, resetWhenSuccess, values]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="categoryFormName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={values.name}
          name="name"
          placeholder="Name"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Controller
          control={control}
          name="status"
          defaultValue={values.status}
          render={({ value, onChange }) => (
            <>
              <Form.Check
                inline
                checked={!!value}
                label="On"
                type="radio"
                id="svconfFormStatusOn"
                onChange={() => onChange(1)}
              />
              <Form.Check
                inline
                checked={!value}
                label="Off"
                type="radio"
                id="svconfFormStatusOff"
                onChange={() => onChange(0)}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group controlId="categoryFormCode">
        <Form.Label>Code</Form.Label>
        <Form.Control
          defaultValue={values.code}
          name="code"
          placeholder="Code"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group controlId="categoryFormParent">
        <Form.Label>Parent</Form.Label>
        <Controller
          control={control}
          name="parentId"
          defaultValue={values.parentId}
          render={({ onChange }) => (
            <Select
              options={parent}
              onChange={(selected) => onChange(selected.value)}
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="categoryFormDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          defaultValue={values.description}
          name="description"
          placeholder="Description"
          ref={register}
          rows="3"
        />
      </Form.Group>
      <Button type="submit" variant="primary" disabled={loading}>
        {loading ? <Spinner animation="border" /> : 'Gửi'}
      </Button>
    </Form>
  );
}
