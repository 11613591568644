import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

export function CreateJobForm({
  alert,
  loading,
  disabled,
  onSubmit,
  jobTypes,
  categories,
  homeCategories,
  resetWhenSuccess,
  setJobTypeName,
  handleSetCategoryName,
  handleChangeCategory,
  handleGetUserInfo,
  handleSuggestAddresses,
  handleSearchAddress,
  values = {},
}) {
  const {
    control,
    formState: { isSubmitSuccessful },
    reset,
    getValues,
    setValue,
    register,
    handleSubmit,
  } = useForm();

  React.useEffect(() => {
    if (resetWhenSuccess && isSubmitSuccessful) {
      reset({ ...values });
    }
  }, [isSubmitSuccessful, reset, resetWhenSuccess, values]);

  const loadAddressOptions = debounce(async (inputValue, callback) => {
    // set address input
    setValue('address', inputValue);

    const data = await handleSuggestAddresses(inputValue);
    if (data) {
      const addresses = data.map((address) => {
        return {
          value: address.description,
          label: address.description,
        };
      });
      callback(addresses);
    }
  }, 700);

  const onSelectAddress = debounce(async (value) => {
    const addressDetail = await handleSearchAddress(value);

    const location = addressDetail?.geometry?.location;
    if (location) {
      setValue('latitude', location.lat);
      setValue('longitude', location.lng);
    }
  }, 700);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId={`createJobFormParentCategory`}>
        <Form.Label>Root Category </Form.Label>
        <Controller
          control={control}
          placeholder={`Parent Category`}
          name="parentCategory"
          required
          render={({ onChange }) => (
            <Select
              options={homeCategories.map((homeCategory) => {
                return {
                  value: homeCategory.categoryId,
                  label: homeCategory.name,
                };
              })}
              onChange={(selected) => {
                handleChangeCategory(0, selected.value);
                onChange(selected.value);
                handleSetCategoryName(0, selected.label);
              }}
            />
          )}
        />
      </Form.Group>
      {categories.map((category, index) => {
        return (
          <Form.Group
            key={`createJobFormCategory${index + 1}`}
            controlId={`createJobFormCategory${index + 1}`}
          >
            <Form.Label>Category {index + 1}</Form.Label>
            <Controller
              control={control}
              name={`category${index + 1}`}
              placeholder={`Category ${index + 1}`}
              render={({ onChange }) => (
                <Select
                  options={category.map((subcate) => {
                    return {
                      value: subcate.id,
                      label: subcate.name,
                    };
                  })}
                  onChange={(selected) => {
                    handleChangeCategory(index + 1, selected.value);
                    onChange(selected.value);
                    handleSetCategoryName(index, selected.label);
                  }}
                />
              )}
            />
          </Form.Group>
        );
      })}
      <Form.Group controlId="createJobFormJobType">
        <Form.Label>Job Type</Form.Label>
        <Controller
          control={control}
          placeholder={`Job Type`}
          name="jobType"
          required
          render={({ onChange }) => (
            <Select
              options={jobTypes.map((jobType) => {
                return {
                  value: jobType.id,
                  label: jobType.name,
                };
              })}
              onChange={(selected) => {
                onChange(selected.value);
                setJobTypeName(selected.label);
              }}
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="createJobFormQuantity">
        <Form.Label>Quantity</Form.Label>
        <Form.Control
          defaultValue={values.quantity}
          name="quantity"
          placeholder="Input quantity"
          ref={register}
          required
          type="number"
        />
      </Form.Group>
      <Form.Group controlId="createJobFormPhoneNumber">
        <Form.Label>Phone Number</Form.Label>
        <Row>
          <Col>
            <Form.Control
              defaultValue={values.phoneNumber}
              name="phoneNumber"
              placeholder="Input phone number"
              ref={register}
              required
            />
          </Col>
          <Col>
            <Button
              onClick={async () => {
                const userInfo = await handleGetUserInfo(
                  getValues('phoneNumber')
                );

                // set customer name
                if (userInfo?.name) {
                  setValue('fullName', userInfo.name);
                }
              }}
            >
              Load user info
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="createJobFormFullname">
        <Form.Label>Customer Name</Form.Label>
        <Form.Control
          defaultValue={values.fullName}
          name="fullName"
          placeholder="Input customer name"
          ref={register}
          required
        />
      </Form.Group>
      <Form.Group controlId="createJobFormAddress">
        <Form.Label>Address</Form.Label>
        <p
          style={{
            color: '#6f8e1e',
          }}
        >
          {getValues('address')}
        </p>
        <Controller
          control={control}
          name="address"
          defaultValue={values.address}
          render={({ onChange }) => (
            <AsyncSelect
              cacheOptions
              loadOptions={loadAddressOptions}
              onChange={async (selected) => {
                onChange(selected.value);
                onSelectAddress(selected.value);
              }}
            />
          )}
        />
        <Form.Control
          defaultValue={values.latitude}
          name="latitude"
          ref={register}
          type="hidden"
          required
        />
        <Form.Control
          defaultValue={values.longitude}
          name="longitude"
          ref={register}
          type="hidden"
          required
        />
      </Form.Group>
      <Form.Group controlId="createJobFormWorkingTime">
        <Form.Label>Working Time</Form.Label>
        <Form.Control
          defaultValue={values.workingTime}
          name="workingTime"
          placeholder="Input workingTime"
          ref={register}
          type="datetime-local"
          required
        />
      </Form.Group>
      {/* <Form.Group controlId="createJobFormCostForWorker">
        <Form.Label>Cost For Worker</Form.Label>
        <Form.Control
          defaultValue={values.costForWorker}
          name="costForWorker"
          placeholder="Input cost for worker"
          ref={register}
          required
          type="number"
        />
      </Form.Group> */}
      <Form.Group controlId="createJobFormCostForWorker">
        <Form.Label>Cod Amount</Form.Label>
        <Form.Control
          defaultValue={values.codAmount}
          name="codAmount"
          placeholder="Input Cod Amount"
          ref={register}
          required
          type="number"
        />
      </Form.Group>
      <Button disabled={loading} type="submit" variant="primary">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          'Xác nhận'
        )}
      </Button>
    </Form>
  );
}
