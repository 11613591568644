import { format } from 'date-fns';
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { formatJobStatus, formatJobType } from '../../utils/format';
import { fetchJobDetail, selectJobEntity } from './jobSlice';
import { selectUser } from './userSlice';

export function JobDetail() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const job = useSelector(selectJobEntity);
  const { id } = useParams();

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchJobDetail({ jobId: id }));
    }
  }, [dispatch, user.isLoggedIn, id]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const jobDetail = job?.jobDetail;

  if (jobDetail == null) {
    return <Spinner animation="border" color="black" />;
  }

  const jobItems = jobDetail.jobItems;

  return (
    <Row>
      <Col>
        <h4>{jobDetail.title}</h4>
        <Row>
          <Col md={4}>
            <Card>
              <Card.Header>Thông tin khách</Card.Header>
              <Card.Body>
                <p>
                  <b>Tên:</b> {jobDetail.customerInfo.fullName}
                </p>
                <p>
                  <b>SDT:</b> {jobDetail.customerInfo.phoneNumber}
                </p>
                <p>
                  <b>Địa chỉ:</b> {jobDetail.customerInfo.address}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Header>Địa điểm làm việc</Card.Header>
              <Card.Body>
                <p>
                  <b>Địa chỉ:</b> {jobDetail.workingPlace.address}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Header>Thông tin thợ</Card.Header>
              <Card.Body>
                <p>
                  <b>Tên:</b> {jobDetail.workerJob?.worker?.fullName}
                </p>
                <p>
                  <b>SDT:</b> {jobDetail.workerJob?.worker?.phoneNumber}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>Thông tin job</Card.Header>
              <Card.Body>
                {jobDetail?.deepLink && (
                  <p>
                    <b>Link:</b>{' '}
                    <a
                      href={jobDetail?.deepLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {jobDetail?.deepLink}
                    </a>
                  </p>
                )}
                <p>
                  <b>Title:</b> {jobDetail.title}
                </p>
                <p>
                  <b>Job Type:</b> {formatJobType(jobDetail.jobType)}
                </p>
                <p>
                  <b>Status:</b> {formatJobStatus(jobDetail.status)}
                </p>
                <p>
                  <b>Cost For Worker:</b>{' '}
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(jobDetail.costForWorker)}
                </p>
                <p>
                  <b>Cod Amount:</b>{' '}
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(jobDetail.codAmount)}
                </p>
                <p>
                  <b>Working Date:</b>{' '}
                  {format(new Date(jobDetail.workingDate), 'dd/MM/yyyy')}{' '}
                  {format(new Date(jobDetail.workingTime), 'HH:mm:ss')}
                </p>
                <p>
                  <b>Mô tả:</b> {jobDetail.description}
                </p>
                <p></p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        {jobItems.map((jobItem, index) => {
          return (
            <Row key={index}>
              <Col>
                <Card>
                  <Card.Header>Thông tin sản phẩm {index + 1}</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <h4>Thông tin môi trường</h4>
                        <p>
                          <b>Skill Code:</b> {jobItem.skillCode}
                        </p>
                        <p>
                          <b>Quantity:</b> {jobItem.quantity}
                        </p>
                        <p>
                          <b>Background:</b> {jobItem.background}
                        </p>
                        <b>Ảnh thực tế</b>
                        {jobItem.attachments && (
                          <Row>
                            {jobItem.attachments.map((attachment, index) => {
                              return (
                                <Col xs={6} md={4} key={index}>
                                  {attachment.type === 'image' && (
                                    <Image src={attachment.uri} thumbnail />
                                  )}
                                  {attachment.type === 'video' && (
                                    <video
                                      src={attachment.uri}
                                      width="100%"
                                      height="auto"
                                      controls="controls"
                                    />
                                  )}
                                </Col>
                              );
                            })}
                          </Row>
                        )}
                      </Col>
                      <Col md={6}>
                        {jobItem.additionalInfo && (
                          <div>
                            <h4>Thông tin sản phẩm tùy chọn</h4>
                            <p>
                              {' '}
                              <b>Name:</b> {jobItem.additionalInfo.name}
                            </p>
                            <p>
                              {' '}
                              <b>SKU:</b> {jobItem.additionalInfo.sku}
                            </p>
                            <p>
                              {' '}
                              <b>Description:</b>{' '}
                              {jobItem.additionalInfo.description}
                            </p>
                            <p>
                              {' '}
                              <b>Category Code:</b>{' '}
                              {jobItem.additionalInfo.categoryCode}
                            </p>
                            <p>
                              <b>Background:</b>{' '}
                              {jobItem.additionalInfo.background}
                            </p>
                            <p>
                              {' '}
                              <b>Ảnh sản phẩm</b>
                            </p>
                            <Row>
                              {jobItem.additionalInfo?.attachments?.map(
                                (attachment, index) => {
                                  return (
                                    <Col xs={6} md={4} key={index}>
                                      {attachment.type === 'image' && (
                                        <Image src={attachment.uri} thumbnail />
                                      )}
                                      {attachment.type === 'video' && (
                                        <video
                                          src={attachment.uri}
                                          width="100%"
                                          height="auto"
                                          controls="controls"
                                        />
                                      )}
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
}
