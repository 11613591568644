import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';

import { Pagination } from '../../components/Pagination';
import * as constants from '../../utils/constants';
import { formatJobStatus } from '../../utils/format';
import { ChangeCostForm } from './forms/ChangeCostForm';
import {
  assignJob,
  cancelJob,
  changeJobCost,
  fetchListJob,
  selectJobEntity,
} from './jobSlice';
import { fetchListSkillType, selectSkillTypeEntity } from './skillTypeSlice';
import { selectUser } from './userSlice';
import { selectWorkerEntity } from './workerSlice';

export function Job() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const job = useSelector(selectJobEntity);
  const worker = useSelector(selectWorkerEntity);
  const skillType = useSelector(selectSkillTypeEntity);
  const [page, setPage] = useState(1);
  const [workerIds, setWorkerIds] = useState({});

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(
        fetchListJob({ page, orderBy: 'createdAt', orderDirection: 'desc' })
      );
      dispatch(fetchListSkillType());
    }
  }, [dispatch, user.isLoggedIn, page]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Row>
      <Col>
        <h4>Job</h4>
        <hr />
        <Link to="/job/create">
          <Button>Tạo job</Button>
        </Link>
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <th>Job Id</th>
              <th>Tiêu đề</th>
              <th>Mô tả</th>
              <th>Loại công việc</th>
              <th>Giá tiền</th>
              <th>Ngày lắp đặt</th>
              <th>Thu hộ</th>
              <th>Trạng thái</th>
              <th>Nơi làm việc</th>
              <th>Thông tin khách</th>
              <th>Ngày tạo</th>
              <th>Chỉnh sửa giá tiền</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {job.ids.map((id) => (
              <tr key={id}>
                <td>{job.entities[id].id}</td>
                <td>{job.entities[id].title}</td>
                <td>{job.entities[id].description}</td>
                <td>{skillType.mapSkillType[job.entities[id].jobType]}</td>
                <td>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(job.entities[id].costForWorker)}
                </td>
                <td>
                  {format(new Date(job.entities[id].workingDate), 'dd/MM/yyyy')}{' '}
                  {format(new Date(job.entities[id].workingTime), 'HH:mm:ss')}
                </td>
                <td>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(job.entities[id]?.codAmount)}
                </td>
                <td>{formatJobStatus(job.entities[id].status)}</td>
                <td>{job.entities[id].workingPlace.street}</td>
                <td>
                  <p>
                    <b>Tên: </b>
                    {job.entities[id]?.customerInfo?.fullName}
                  </p>
                  <p>
                    <b>SDT: </b>
                    {job.entities[id]?.customerInfo?.phoneNumber}
                  </p>
                  <p>
                    <b>Địa chỉ: </b>
                    {job.entities[id]?.customerInfo?.address}
                  </p>
                </td>
                <td>
                  {format(
                    new Date(job.entities[id].createdAt),
                    'dd/MM/yyyy HH:mm:ss'
                  )}
                </td>
                <td style={{ minWidth: '300px' }}>
                  {job.entities[id].status !==
                    constants.JOB_STATUS.CANCEL_BY_WORKER &&
                    job.entities[id].status !==
                      constants.JOB_STATUS.CANCEL_BY_CUSTOMER &&
                    job.entities[id].status !==
                      constants.JOB_STATUS.CANCEL_BY_ADMIN &&
                    job.entities[id].status !== constants.JOB_STATUS.DONE && (
                      <ChangeCostForm
                        id={id}
                        disabled={job.loading === 'pending'}
                        values={{
                          costForWorker: 0,
                          codAmount: 0,
                        }}
                        onSubmit={(data) => {
                          dispatch(
                            changeJobCost({
                              costForWorker: parseInt(data.costForWorker),
                              codAmount: parseInt(data.codAmount),
                              jobId: job.entities[id].id,
                            })
                          );
                        }}
                      />
                    )}
                </td>
                <td style={{ minWidth: '300px' }}>
                  {job.entities[id].status !==
                    constants.JOB_STATUS.CANCEL_BY_WORKER &&
                    job.entities[id].status !==
                      constants.JOB_STATUS.CANCEL_BY_CUSTOMER &&
                    job.entities[id].status !==
                      constants.JOB_STATUS.CANCEL_BY_ADMIN &&
                    job.entities[id].status !== constants.JOB_STATUS.DONE && (
                      <>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isLoading={worker.loading === 'pending'}
                          isClearable={true}
                          isSearchable={true}
                          name="worker"
                          placeholder={'Chọn thợ'}
                          options={worker.ids.map((id) => {
                            return {
                              label: worker.entities[id].fullName,
                              value: worker.entities[id].id,
                            };
                          })}
                          onChange={(worker) => {
                            let tempWorker = { ...workerIds };
                            if (worker.value) {
                              tempWorker[id] = worker.value;
                              setWorkerIds(tempWorker);
                            }
                          }}
                        />
                        <br />
                        <Button
                          onClick={() => {
                            console.log({
                              jobId: job.entities[id].id,
                              workerId: workerIds[id],
                            });
                            dispatch(
                              assignJob({
                                jobId: job.entities[id].id,
                                workerId: workerIds[id],
                              })
                            );
                          }}
                        >
                          Giao việc
                        </Button>
                      </>
                    )}
                  <br />
                  <br />
                  {job.entities[id].status === constants.JOB_STATUS.NEW && (
                    <Button
                      variant="danger"
                      onClick={() => {
                        dispatch(cancelJob(job.entities[id].id));
                      }}
                    >
                      Hủy
                    </Button>
                  )}
                  <br />
                  <br />
                  <Link
                    to={{
                      pathname: `/job/detail/${job.entities[id].id}`,
                      state: job.entities[id],
                    }}
                  >
                    Chi tiết
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={page}
          totalPage={job.totalPage}
          totalItem={job.count}
          onPageChange={(page) => setPage(page)}
        />
      </Col>
    </Row>
  );
}
