import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CreateJobForm } from './forms/CreateJobForm';
import {
  selectJobEntity,
  fetchAddressSuggestion,
  fetchSearchAddress,
  fetchCustomerInfo,
  createJob,
} from './jobSlice';
import { selectUser } from './userSlice';
import {
  selectCategoryEntity,
  setIndexCategory,
  fetchListHomeCategory,
  fetchListChildCategory,
} from './categorySlice';
import { fetchListServiceJobType, selectServiceEntity } from './serviceSlice';

export function JobCreate() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const category = useSelector(selectCategoryEntity);
  const service = useSelector(selectServiceEntity);
  const job = useSelector(selectJobEntity);
  const [categoryIds, setCategoryIds] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [jobTypeName, setJobTypeName] = useState('');
  const [formAlert, setFormAlert] = useState(null);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchListHomeCategory());
    }
  }, [dispatch, user.isLoggedIn]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const handleChangeCategory = (index, value) => {
    let newCategoryIds = [...categoryIds];
    newCategoryIds.splice(index, newCategoryIds.length);
    newCategoryIds.push(value);
    setCategoryIds(newCategoryIds);

    dispatch(setIndexCategory(index + 1));

    dispatch(
      fetchListServiceJobType({
        categoryId: newCategoryIds[newCategoryIds.length - 1],
      })
    );
    dispatch(
      fetchListChildCategory({
        parentId: newCategoryIds[newCategoryIds.length - 1],
      })
    );
  };

  const handleGetUserInfo = async (phone) => {
    try {
      const result = await dispatch(fetchCustomerInfo({ phoneNumber: phone }));
      const data = unwrapResult(result);

      return data;
    } catch (e) {
      console.error(e);
      setFormAlert(
        <Alert key="error" variant="danger">
          Có lỗi xảy ra khi tải thông tin customer
        </Alert>
      );
      setTimeout(() => {
        setFormAlert(null);
      }, 10000);
      return;
    }
  };

  const handleSuggestAddresses = async (address) => {
    try {
      const result = await dispatch(fetchAddressSuggestion({ address }));
      const data = unwrapResult(result);

      return data;
    } catch (e) {
      console.error(e);
      setFormAlert(
        <Alert key="error" variant="danger">
          Có lỗi xảy ra khi tìm địa chỉ
        </Alert>
      );
      setTimeout(() => {
        setFormAlert(null);
      }, 10000);
      return;
    }
  };

  const handleSearchAddress = async (address) => {
    try {
      const result = await dispatch(fetchSearchAddress({ address }));
      const data = unwrapResult(result);

      return data;
    } catch (e) {
      console.error(e);
      setFormAlert(
        <Alert key="error" variant="danger">
          Có lỗi xảy ra khi tìm địa chỉ
        </Alert>
      );
      setTimeout(() => {
        setFormAlert(null);
      }, 10000);
      return;
    }
  };

  const handleSetCategoryName = (index, value) => {
    let newCategoryName = [...categoryName];
    newCategoryName.splice(index, newCategoryName.length);
    newCategoryName.push(value);
    setCategoryName(newCategoryName);
  };

  const onSubmit = async (data) => {
    const {
      jobType,
      address,
      workingTime,
      fullName,
      phoneNumber,
      latitude,
      longitude,
      codAmount,
      quantity,
    } = data;

    const bodyRequest = {
      title: `${jobTypeName} ${categoryName.join(' ')}`,
      description: '',
      jobType: jobType,
      workingPlace: {
        province: '',
        city: '',
        ward: '',
        address: address,
      },
      workingTime: workingTime,
      workingDate: workingTime,
      customerInfo: {
        fullName: fullName,
        phoneNumber: phoneNumber,
        address: address,
      },
      latitude: latitude,
      longitude: longitude,
      costForWorker: 0,
      codAmount: parseInt(codAmount),
      attachments: [],
      categoryIds: categoryIds,
      items: [{ quantity }],
    };

    try {
      const result = await dispatch(createJob(bodyRequest));
      unwrapResult(result);

      setFormAlert(
        <Alert key="success" variant="success">
          <p>Tạo job thành công</p>
        </Alert>
      );
    } catch (e) {
      console.error(e);
      setFormAlert(
        <Alert key="error" variant="danger">
          Có lỗi xảy ra khi tạo job
        </Alert>
      );
      setTimeout(() => {
        setFormAlert(null);
      }, 10000);
      return;
    }
  };

  return (
    <Row>
      <Col md={6}>
        <h4>
          <Link to="/job">Trở về</Link>
        </h4>
        <hr />
        <CreateJobForm
          values={{
            latitude: 0,
            longitude: 0,
          }}
          homeCategories={category.homeCategories}
          categories={category.categories}
          jobTypes={service.jobTypes}
          alert={formAlert}
          setJobTypeName={setJobTypeName}
          resetWhenSuccess
          loading={job.loading === 'pending'}
          suggestionAddresses={job.suggestionAddresses}
          handleSetCategoryName={handleSetCategoryName}
          handleChangeCategory={handleChangeCategory}
          handleGetUserInfo={handleGetUserInfo}
          handleSuggestAddresses={handleSuggestAddresses}
          handleSearchAddress={handleSearchAddress}
          onSubmit={onSubmit}
        />
      </Col>
    </Row>
  );
}
