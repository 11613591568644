import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

export function LoginForm({ alert, onSubmit, values = {} }) {
  const { register, handleSubmit } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="loginFormPhone">
        <Form.Label>Số điện thoại</Form.Label>
        <Form.Control
          defaultValue={values.phone}
          name="phone"
          placeholder="Nhập số điện thoại"
          ref={register}
          type="phone"
        />
      </Form.Group>
      <Button type="submit" variant="primary">
        Xác nhận
      </Button>
    </Form>
  );
}
