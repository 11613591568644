import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

export function SvconfForm({
  alert,
  categories,
  loading,
  resetWhenSuccess,
  values = {},
  onSubmit,
}) {
  const {
    control,
    formState: { isSubmitSuccessful },
    handleSubmit,
    register,
    reset,
  } = useForm();

  React.useEffect(() => {
    if (resetWhenSuccess && isSubmitSuccessful) {
      reset({ ...values });
    }
  }, [isSubmitSuccessful, reset, resetWhenSuccess, values]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="svconfFormName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={values.name}
          name="name"
          placeholder="Name"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Controller
          control={control}
          name="status"
          defaultValue={values.status}
          render={({ value, onChange }) => (
            <>
              <Form.Check
                inline
                checked={!!value}
                label="On"
                type="radio"
                id="svconfFormStatusOn"
                onChange={() => onChange(1)}
              />
              <Form.Check
                inline
                checked={!value}
                label="Off"
                type="radio"
                id="svconfFormStatusOff"
                onChange={() => onChange(0)}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group controlId="svconfFormCategoryId">
        <Form.Label>Category</Form.Label>
        <Controller
          control={control}
          name="categoryId"
          defaultValue={values.categoryId}
          render={({ onChange }) => (
            <Select
              options={categories}
              onChange={(selected) => onChange(selected.value)}
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="svconfFormIcon">
        <Form.Label>Icon</Form.Label>
        <Form.Control
          defaultValue={values.icon}
          name="icon"
          placeholder="Icon"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Form.Group controlId="svconfFormLink">
        <Form.Label>Link</Form.Label>
        <Form.Control
          defaultValue={values.link}
          name="link"
          placeholder="Link"
          ref={register}
          type="text"
        />
      </Form.Group>
      <Button type="submit" variant="primary" disabled={loading}>
        {loading ? <Spinner animation="border" /> : 'Gửi'}
      </Button>
    </Form>
  );
}
