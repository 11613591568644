import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { selectUser } from "../features/admin/userSlice";

function GuestRoute({ children, ...rest }) {
  const user = useSelector(selectUser);

  if (user.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <Route {...rest}>{children}</Route>;
}

export default GuestRoute;
