import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

export function VerificationCodeForm({ alert, onSubmit, values = {} }) {
  const { register, handleSubmit } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {alert ? alert : null}
      <Form.Group controlId="verificationCodeFormPhone">
        <Form.Label>Mã code</Form.Label>
        <Form.Control name="code" placeholder="Nhập mã code" ref={register} />
      </Form.Group>
      <div id="recaptcha-container" />
      <Button type="submit" variant="primary">
        Xác nhận
      </Button>
    </Form>
  );
}
