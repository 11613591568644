import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { selectCategoryEntity, updateCategory } from './categorySlice';
import { CategoryForm } from './forms/CategoryForm';

export function CategoryEdit() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [alert, setAlert] = useState(null);
  const category = useSelector(selectCategoryEntity);
  const categoryMapping = useSelector((state) => state.category.entities);

  if (!state) {
    return <Redirect to="/category" />;
  }

  return (
    <Row className="justify-content-center">
      <Col md={4}>
        <h4>
          <Link to="/category">Trở về</Link>
        </h4>
        <hr />
        <CategoryForm
          alert={
            alert && <Alert variant={alert.variant}>{alert.content}</Alert>
          }
          loading={category.loading === 'pending'}
          parent={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          values={{ ...state }}
          onSubmit={async (data) => {
            setAlert(null);

            try {
              const result = await dispatch(
                updateCategory({
                  ...state,
                  ...data,
                })
              );

              unwrapResult(result);
              setAlert({ variant: 'success', content: 'Thành công!' });
            } catch (e) {
              setAlert({ variant: 'danger', content: e.message });
            }
          }}
        />
      </Col>
    </Row>
  );
}
