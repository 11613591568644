import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const fetchListHomeCategory = createAsyncThunk(
  'category/fetchListHomeCategory',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListHomeCategory(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchListChildCategory = createAsyncThunk(
  'category/fetchListChildCategory',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListChildCategory(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const fetchListCategory = createAsyncThunk(
  'category/fetchListCategory',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListCategory(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const createCategory = createAsyncThunk(
  'category/createCategory',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.createCategory(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.updateCategory(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

const categoryAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const categorySlice = createSlice({
  initialState: categoryAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
    parentCategory: -1,
    homeCategories: [],
    indexCategory: 0,
    categories: [],
  }),
  name: 'category',
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setIndexCategory: (state, { payload }) => {
      state.indexCategory = payload;
    },
  },
  extraReducers: {
    [fetchListCategory.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListCategory.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        categoryAdapter.setAll(state, payload);
      }
    },
    [fetchListCategory.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [fetchListHomeCategory.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListHomeCategory.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        const services = payload.services.filter(
          (service) => service.status === 1
        );

        state.homeCategories = services;
      }
    },
    [fetchListHomeCategory.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [fetchListChildCategory.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListChildCategory.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        let newCategories = [...state.categories];
        newCategories.splice(state.indexCategory - 1, newCategories.length);
        if (payload.length > 0) {
          newCategories.push(payload);
        }

        state.categories = newCategories;
      }
    },
    [fetchListChildCategory.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [createCategory.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [createCategory.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        categoryAdapter.addOne(state, payload);
      }
    },
    [createCategory.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [updateCategory.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [updateCategory.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        categoryAdapter.updateOne(state, payload);
      }
    },
    [updateCategory.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectCategory = (id) => (state) => state.category.entities[id];

export const selectCategoryEntity = (state) => state.category;

export const { setError, setIndexCategory } = categorySlice.actions;

export default categorySlice.reducer;
