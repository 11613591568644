import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';

export function Header() {
  const location = useLocation();
  
  const [activeKey, setActiveKey] = useState(location.pathname);
  useEffect(() => {
    setActiveKey(location.pathname)
  }, [location.pathname])
  
  return (
    <Navbar as="header" bg="dark" sticky="top" variant="dark">
      <Navbar.Brand as="span">TGLD</Navbar.Brand>
      <Navbar.Toggle aria-controls="adminPageHeader" />
      <Navbar.Collapse id="adminPageHeader">
        <Nav
          activeKey={activeKey}
          as="ul"
          className="mr-auto"
          onSelect={(key) => setActiveKey(key)}
        >
          <Nav.Item as="li">
            <Nav.Link as={Link} eventKey={'/job'} to="/job">
              Job
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link as={Link} eventKey={'/skill'} to="/skill">
              Skill
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link as={Link} eventKey={'/category'} to="/category">
              Category
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link as={Link} eventKey={'/service'} to="/service">
              Service
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link as={Link} eventKey={'/svconf'} to="/svconf">
              Consumer Svconf
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link as={Link} eventKey={'/product'} to="/product">
              Product
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav as="ul">
          <Nav.Item as="li">
            <Button
              onClick={() => {
                window.localStorage.removeItem('token');
                window.location.reload();
              }}
            >
              Đăng Xuất
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
