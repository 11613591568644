import React, { useEffect, useState, useCallback } from "react";

import { Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Login } from "./features/admin/Login";
import { Admin } from './features/admin/Admin';

import PrivateRoute from "./routes/PrivateRoute";
import GuestRoute from "./routes/GuestRoute";

import { checkLoggedIn } from "./features/admin/userSlice";
import { Spinner } from "react-bootstrap";

export function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const preflightCheck = useCallback(async () => {
    await dispatch(checkLoggedIn());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    preflightCheck();
  }, [preflightCheck]);

  if (loading) {
    return <Spinner className="loading-page" animation="border" role="status" />;
  }

  return (
    <>
      <Switch>
        <GuestRoute path="/login" exact>
          <Login />
        </GuestRoute>
        <PrivateRoute>
          <Admin />
        </PrivateRoute>
      </Switch>
    </>
  );
}
