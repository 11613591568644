import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { SkillForm } from './forms/SkillForm';
import { selectSkillEntity, updateSkill } from './skillSlice';

export function SkillEdit() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [alert, setAlert] = useState(null);

  const skill = useSelector(selectSkillEntity);

  if (!state) {
    return <Redirect to="/skill" />;
  }

  return (
    <Row className="justify-content-center">
      <Col md={4}>
        <h4>
          <Link to="/skill">Trở về</Link>
        </h4>
        <hr />
        <SkillForm
          formType={'edit'}
          alert={
            alert && <Alert variant={alert.variant}>{alert.content}</Alert>
          }
          onSubmit={async (data) => {
            setAlert(null);

            try {
              const result = await dispatch(
                updateSkill({ ...data, skillId: state.id })
              );

              unwrapResult(result);
              setAlert({ variant: 'success', content: 'Thành công!' });
            } catch (e) {
              setAlert({ variant: 'danger', content: e.message });
            }
          }}
          loading={skill.loading}
          values={{ ...state }}
        />
      </Col>
    </Row>
  );
}
