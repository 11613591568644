import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import {
  createCategory,
  fetchListCategory,
  selectCategoryEntity,
} from './categorySlice';
import { CategoryForm } from './forms/CategoryForm';
import { selectUser } from './userSlice';

export function Category() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const category = useSelector(selectCategoryEntity);
  const categoryMapping = useSelector((state) => state.category.entities);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchListCategory());
    }
  }, [dispatch, user.isLoggedIn]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Row>
      <Col>
        <h4>Category</h4>
        <hr />
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Parent</th>
              <th>Code</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {category.ids.map((id) => (
              <tr key={id}>
                <td>{category.entities[id].name}</td>
                <td>
                  {category.entities[category.entities[id].parentId]?.name}
                </td>
                <td>{category.entities[id].code}</td>
                <td>{category.entities[id].description}</td>
                <td>
                  <Link
                    to={{
                      pathname: '/category/edit',
                      state: category.entities[id],
                    }}
                  >
                    Sửa
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col md={3}>
        <h4>Category Form</h4>
        <CategoryForm
          alert={
            category.error && (
              <Alert key="error" variant="danger">
                {category.error.message}
              </Alert>
            )
          }
          loading={category.loading === 'pending'}
          parent={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          resetWhenSuccess
          values={{
            name: '',
            status: 1,
            parentId: null,
            code: '',
            description: '',
          }}
          onSubmit={async (data) => {
            await dispatch(createCategory(data));
          }}
        />
      </Col>
    </Row>
  );
}
