import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const fetchListSkillType = createAsyncThunk(
  'skillType/fetchListSkillType',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListSkillType(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

const skillTypeAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const skillTypeSlice = createSlice({
  initialState: skillTypeAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
    mapSkillType: {},
  }),
  name: 'skillType',
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [fetchListSkillType.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListSkillType.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        const skillTypes = payload;
        let objMap = {};
        skillTypes.forEach((skilType) => {
          objMap[skilType.id] = skilType.name;
        });
        state.mapSkillType = objMap;

        skillTypeAdapter.setAll(state, payload);
      }
    },
    [fetchListSkillType.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectSkillType = (id) => (state) => state.skillType.entities[id];

export const selectSkillTypeEntity = (state) => ({
  ids: state.skillType.ids,
  entities: state.skillType.entities,
  error: state.skillType.error,
  mapSkillType: state.skillType.mapSkillType,
});

export const { setError, setMapSkillType } = skillTypeSlice.actions;

export default skillTypeSlice.reducer;
