import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { SvconfForm } from './forms/SvconfForm';
import { selectSvconfEntity, updateSvconf } from './svconfSlice';

export function SvconfEdit() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [alert, setAlert] = useState(null);
  const svconf = useSelector(selectSvconfEntity);
  const categoryMapping = useSelector((state) => state.category.entities);

  if (!state) {
    return <Redirect to="/svconf" />;
  }

  return (
    <Row className="justify-content-center">
      <Col md={4}>
        <h4>
          <Link to="/svconf">Trở về</Link>
        </h4>
        <hr />
        <SvconfForm
          alert={
            alert && <Alert variant={alert.variant}>{alert.content}</Alert>
          }
          categories={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          loading={svconf.loading === 'pending'}
          values={{ ...state }}
          onSubmit={async (data) => {
            setAlert(null);

            try {
              const result = await dispatch(
                updateSvconf({ ...state, ...data })
              );

              unwrapResult(result);
              setAlert({ variant: 'success', content: 'Thành công!' });
            } catch (e) {
              setAlert({ variant: 'danger', content: e.message });
            }
          }}
        />
      </Col>
    </Row>
  );
}
