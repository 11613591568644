import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';

export const fetchListProduct = createAsyncThunk(
  'product/fetchListProduct',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListProduct(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (body) => {
    try {
      const data = await api.createProduct(body);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async (body) => {
    try {
      const data = await api.updateProduct(body);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  }
);

const productAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const productSlice = createSlice({
  initialState: productAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  }),
  name: 'product',
  reducers: {},
  extraReducers: {
    [fetchListProduct.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListProduct.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        productAdapter.setAll(state, payload.rows);
        state.totalPage = payload.totalPage;
        state.count = payload.count;
      }
    },
    [fetchListProduct.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [createProduct.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [createProduct.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        productAdapter.addOne(state, payload);
      }
    },
    [createProduct.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [updateProduct.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [updateProduct.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        productAdapter.updateOne(state, payload);
      }
    },
    [updateProduct.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectProduct = (id) => (state) => state.product.entities[id];

export const selectProductEntity = (state) => state.product;

export default productSlice.reducer;
