import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import firebaseApp from '../../firebaseApp';
import { LoginForm } from './forms/LoginForm';
import { VerificationCodeForm } from './forms/VerificationCodeForm';
import { logIn, selectUser } from './userSlice';

export function Login() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [showInputCode, setShowInputCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sessionInfo, setSessionInfo] = useState('');

  useEffect(() => {
    firebaseApp.auth().languageCode = 'vn';
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      }
    );
    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  const handleSendCode = async (data) => {
    setPhoneNumber(data.phone);
    // reCAPTCHA solved, allow signInWithPhoneNumber.
    var appVerifier = recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(data.phone, appVerifier)
      .then(function (confirmationResult) {
        // console.log(confirmationResult);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setSessionInfo(confirmationResult.verificationId);
        setShowInputCode(true);
      })
      .catch(function (error) {
        console.log(error);
        // Error; SMS not sent
        // ...
        alert('Không thể gửi mã sms');
      });
  };

  const handleLogin = (data) => {
    dispatch(
      logIn({
        phoneNumber: phoneNumber.replace('+84', '0'),
        countryCode: '84',
        code: data.code,
        sessionInfo: sessionInfo,
        role: 'admin',
      })
    );
  };

  if (user.isLoggedIn) {
    return <Redirect to="/job" />;
  }

  return (
    <Row className="justify-content-center">
      <Col md={4}>
        <h4>Đăng nhập</h4>
        <hr />
        <>
          {showInputCode ? (
            <VerificationCodeForm onSubmit={(data) => handleLogin(data)} />
          ) : (
            <LoginForm
              onSubmit={(data) => handleSendCode(data)}
              values={{ phone: '+84347788707' }}
            />
          )}
        </>
      </Col>
    </Row>
  );
}
