import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { fetchListCategory, selectCategoryEntity } from './categorySlice';
import { SvconfForm } from './forms/SvconfForm';
import {
  createSvconf,
  fetchListSvconf,
  selectSvconfEntity,
} from './svconfSlice';
import { selectUser } from './userSlice';

export function Svconf() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const category = useSelector(selectCategoryEntity);
  const categoryMapping = useSelector((state) => state.category.entities);
  const svconf = useSelector(selectSvconfEntity);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(fetchListSvconf());
      dispatch(fetchListCategory());
    }
  }, [dispatch, user.isLoggedIn]);

  if (!user.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Row>
      <Col>
        <h4>Consumer Svconf</h4>
        <hr />
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Category</th>
              <th>Icon</th>
              <th>Link?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {svconf.ids.map((id) => (
              <tr key={id}>
                <td>{svconf.entities[id].name}</td>
                <td>{svconf.entities[id].status ? 'On' : 'Off'}</td>
                <td>
                  {category.entities[svconf.entities[id].categoryId]?.name}
                </td>
                <td>
                  <img
                    src={svconf.entities[id].icon}
                    alt={svconf.entities[id].name}
                    height="50"
                  />
                </td>
                <td>{svconf.entities[id].link}</td>
                <td>
                  <Link
                    to={{
                      pathname: '/svconf/edit',
                      state: svconf.entities[id],
                    }}
                  >
                    Sửa
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col md={3}>
        <h4>Consumer Svconf Form</h4>
        <hr />
        <SvconfForm
          alert={
            svconf.error && (
              <Alert key="error" variant="danger">
                {svconf.error.message}
              </Alert>
            )
          }
          categories={Object.values(categoryMapping).map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          loading={svconf.loading === 'pending'}
          resetWhenSuccess
          values={{
            name: '',
            status: 1,
            categoryId: null,
            icon: '',
            link: '',
          }}
          onSubmit={async (data) => {
            await dispatch(createSvconf(data));
          }}
        />
      </Col>
    </Row>
  );
}
