import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Category } from './Category';
import { CategoryEdit } from './CategoryEdit';
import { Job } from './Job';
import { JobCreate } from './JobCreate';
import { JobDetail } from './JobDetail';
import { Product } from './Product';
import { ProductEdit } from './ProductEdit';
import { Service } from './Service';
import { ServiceEdit } from './ServiceEdit';
import { Skill } from './Skill';
import { SkillEdit } from './SkillEdit';
import { Svconf } from './Svconf';
import { SvconfEdit } from './SvconfEdit';
import { Header } from './layout/Header';
import { checkLoggedIn, selectUser } from './userSlice';
import { fetchListWorker } from './workerSlice';

export function Admin() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(checkLoggedIn());
    if (user.isLoggedIn) {
      dispatch(fetchListWorker({ page: 1 }));
    }
  }, [dispatch, user.isLoggedIn]);

  return (
    <BrowserRouter>
      {user.isLoggedIn && <Header />}
      <Container className="mt-3" fluid>
        <Switch>
          <Route exact path="/">
            <Redirect to="/job" />
          </Route>
          <Route exact path="/job">
            <Job />
          </Route>
          <Route exact path="/job/detail/:id">
            <JobDetail />
          </Route>
          <Route exact path="/job/create">
            <JobCreate />
          </Route>
          <Route exact path="/skill">
            <Skill />
          </Route>
          <Route exact path="/skill/edit">
            <SkillEdit />
          </Route>
          <Route exact path="/category">
            <Category />
          </Route>
          <Route exact path="/category/edit">
            <CategoryEdit />
          </Route>
          <Route exact path="/service">
            <Service />
          </Route>
          <Route exact path="/service/edit">
            <ServiceEdit />
          </Route>
          <Route exact path="/svconf">
            <Svconf />
          </Route>
          <Route exact path="/svconf/edit">
            <SvconfEdit />
          </Route>
          <Route exact path="/product">
            <Product />
          </Route>
          <Route exact path="/product/edit">
            <ProductEdit />
          </Route>
        </Switch>
      </Container>
    </BrowserRouter>
  );
}
