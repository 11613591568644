import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../../app/api';
import * as constants from '../../utils/constants';

export const fetchListSkill = createAsyncThunk(
  'skill/fetchListSkill',
  async (params) => {
    try {
      const p = { ...params };
      const data = await api.fetchListSkill(p);

      return data;
    } catch (e) {
      throw new Error(e.response?.data?.reason);
    }
  }
);

export const createSkill = createAsyncThunk(
  'skill/createSkill',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.createSkill(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

export const updateSkill = createAsyncThunk(
  'skill/updateSkill',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.updateSkill(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

export const deleteSkill = createAsyncThunk(
  'skill/deleteSkill',
  async (body, { dispatch }) => {
    dispatch(setError(null));

    try {
      const data = await api.deleteSkill(body);

      return data;
    } catch (e) {
      dispatch(setError(e));
      throw new Error(e);
    }
  }
);

const skillAdapter = createEntityAdapter({ selectId: (entity) => entity.id });

const skillSlice = createSlice({
  initialState: skillAdapter.getInitialState({
    totalPage: 0,
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  }),
  name: 'skill',
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [fetchListSkill.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [fetchListSkill.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        const skills = payload.skills.filter(
          (skill) => skill.status !== constants.SKILL_STATUS.ARCHIVED
        );

        skillAdapter.setAll(state, skills);
      }
    },
    [fetchListSkill.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [createSkill.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.loading = 'pending';
      }
    },
    [createSkill.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';

        skillAdapter.addOne(state, payload.skill);
      }
    },
    [createSkill.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [updateSkill.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [updateSkill.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.total = state.total + 1;

        skillAdapter.upsertOne(state, payload);
      }
    },
    [updateSkill.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
    [deleteSkill.pending]: (state, { meta }) => {
      if (state.loading === 'idle') {
        state.currentRequestId = meta.requestId;
        state.error = null;
        state.loading = 'pending';
      }
    },
    [deleteSkill.fulfilled]: (state, { meta, payload }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.total = state.total - 1;

        skillAdapter.upsertOne(state, payload);
      }
    },
    [deleteSkill.rejected]: (state, { meta, error }) => {
      if (
        state.loading === 'pending' &&
        state.currentRequestId === meta.requestId
      ) {
        state.currentRequestId = undefined;
        state.error = error;
        state.loading = 'idle';
      }
    },
  },
});

export const selectSkill = (id) => (state) => state.skill.entities[id];

export const selectSkillEntity = (state) => ({
  ids: state.skill.ids,
  entities: state.skill.entities,
  error: state.skill.error,
});

export const { setError } = skillSlice.actions;

export default skillSlice.reducer;
